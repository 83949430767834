/**
 * File library.js
 * 
 * Custom scripts.
 */

(function($) {

	// Global Variables

	var winHeight = $(window).height(),
		header = $('#masthead'),
		headerHeight = header.outerHeight(),
		menuButton = $('.menu-button'),
		homeSlider = $('.home-slideshow'),
		extraTitle = $('#extra .main-title');

	// All-click objects

	function allClick() {
		var clickbox = $('.allclick');
		clickbox.each(function() {
			var $this = $(this);
			$this.css('cursor', 'pointer');
			$this.find('a').on('click', function(e) {
				e.preventDefault();
			});
			
			if ($this.find('a').attr('target') == '_blank') {
				$this.on('click', function(e) {
					window.open($('a', this).attr('href'));
				});
			} else {
				$this.on('click', function(e) {
					window.location.href = $('a', this).attr('href');
				});
			}
		});
	}

	// Smooth scrolling

	function smoothScroll() {
		$("a[href*='#']").not("[href='#'],[data-toggle]").on('click', function(e) {
			if (this.hash !== "") {
				e.preventDefault();

				var hash = this.hash;
				$('html, body').animate({
					scrollTop: $(hash).offset().top - headerHeight
				}, 1000);
			}
		});
	}

	// Following the mouse (only desktop)

	if ( $('body').hasClass('is-desktop') ) {
		$(window).on('mousemove', function(e) {
			$('#cursor').css( 'transform', 'translate(' + e.pageX + 'px,' + e.pageY + 'px)' );
		});
	}

	// Code to run when the document is ready

	$( document ).ready(function() {

		allClick();
		smoothScroll();
		$('.marquee-container').SimpleMarquee({
			container_class: '.marquee-container',
			velocity: 0.05
		});

		// Inhibit click on primary menu items

		$('#primary-menu').find('a[href="#"]').each(function() {
			$(this).on('click', function(e) {
				e.preventDefault();

				$('#primary-menu .menu-item > a').not(this).removeClass('bordered').next('.sub-menu').removeClass('open');

				if ( $(this).hasClass('bordered') ) {
					$(this).removeClass('bordered').next('.sub-menu').removeClass('open');
				} else {
					$(this).addClass('bordered').next('.sub-menu').addClass('open');
				}
			});
		});

		// Owl Carousel calls

		var homeCarousel = $('.home-slideshow .owl-carousel').owlCarousel({
			autoplay: true,
			loop: true,
			items: 1,
			lazyLoad: true,
			dots: true, // true for index bug
			animateOut: 'animate__fadeOut',
			onInitialized: activateElm,
			onChanged: activateElm
		});

		function activateElm(event) {
			changeClass(event.item.index);

			var page = event.page.index;
			if (page < 0) page = 0;
			
			$('.progress-ring .progress-bar').removeClass('active');
			$('.progress-ring .progress-bar.bar' + page).addClass('active');

			$('.controls-wrapper .nav-text').removeClass('active');
			$('.controls-wrapper .nav-text.text' + page).addClass('active');
		}

		function changeClass(slide) {
			setTimeout(function() {
				$('.owl-item').each(function() {
					if ($(this).index() === slide) {
						$(this).addClass('animated');
					} else {
						$(this).removeClass('animated');
					}
				});
			}, 250);
		}

		$('.progress-ring .progress-label').on('click', function(e) {
			var slide = $(this).attr('data-slide');
			homeCarousel.trigger('to.owl.carousel', [slide, 500]);
		});

		$('.controls-wrapper, .progress-ring .progress-label').on('mouseenter', function(e) {
			homeCarousel.trigger('stop.owl.autoplay');
		});

		$('.controls-wrapper, .progress-ring .progress-label').on('mouseleave', function(e) {
			homeCarousel.trigger('play.owl.autoplay', [5000]);
		});

		$('.controls-wrapper .nav-prev').on('click', function(e) {
			homeCarousel.trigger('prev.owl.carousel', [500]);
		});

		$('.controls-wrapper .nav-next').on('click', function(e) {
			homeCarousel.trigger('next.owl.carousel', [500]);
		});

		$('.header-slideshow .owl-carousel').owlCarousel({
			autoplay: true,
			loop: true,
			items: 1,
			lazyLoad: true,
			dots: false,
			animateOut: 'animate__fadeOut',
			nav: true,
			navText: [ '<iconify-icon icon="mdi:chevron-left"></iconify-icon>', '<iconify-icon icon="mdi:chevron-right"></iconify-icon>' ],
			navSpeed: 1500,
			onInitialized: function(event) {
				changeClass(event.item.index);
			},
			onChanged: function(event) {
				changeClass(event.item.index);
			}
		});

		$('.reasons-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			dotsContainer: '.reason-dots',
			animateIn: 'animate__slideInLeft',
			animateOut: 'animate__fadeOutRight'
		});

		$('.rooms-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			margin: 20,
			center: true,
			dots: false,
			animateOut: 'animate__fadeOut',
			responsive: {
				768: {
					items: 2,
					margin: 60
				},
				992: {
					items: 2,
					margin: 80
				},
				1200: {
					items: 3,
					margin: 120
				}
			}
		});

		$('.single-room-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			lazyLoad: true,
			dots: false,
			nav: true,
			navText: [ '<iconify-icon icon="mdi:chevron-left"></iconify-icon>', '<iconify-icon icon="mdi:chevron-right"></iconify-icon>' ],
			navSpeed: 1500
		});

		$('.reviews-slideshow .owl-carousel').owlCarousel({
			loop: true,
			items: 1,
			dots: false,
			nav: true,
			navContainer: '.reviews-nav',
			navText: ['<iconify-icon icon="mdi:menu-left-outline" height="60"></iconify-icon>','<iconify-icon icon="mdi:menu-right-outline" height="60"></iconify-icon>']
		});

		var offersCarousel = $('.offers-slideshow .owl-carousel').owlCarousel({
			items: 1,
			dots: false,
			nav: true,
			navText: [ '<iconify-icon icon="mdi:minus"></iconify-icon>', '<iconify-icon icon="mdi:minus"></iconify-icon>' ],
			responsive: {
				768: {
					items: 2,
					margin: 40
				},
				992: {
					items: 3,
					margin: 80
				},
				1200: {
					items: 3,
					margin: 120
				}
			}
		});

		$('.services-slideshow .owl-carousel').owlCarousel({
			items: 1,
			animateOut: 'animate__fadeOut',
			dotsContainer: '.services-dots',
			nav: true,
			navText: [ '<iconify-icon icon="mdi:chevron-left"></iconify-icon>', '<iconify-icon icon="mdi:chevron-right"></iconify-icon>' ],
			navSpeed: 1500
		});

		// Offers filtering

		$('.offers-filters').on('click', '.filter', function() {
			var item = $(this),
				filter = item.data('filter');

			item.addClass('active').siblings().removeClass('active');
			offersCarousel.owlcarousel2_filter(filter);
		});

		// Items filtering

		$('.item-filters').on('click', '.filter', function(event) {
			event.preventDefault();
			$(this).addClass('active').siblings().removeClass('active');

			var mix = $(this).data('filter');
			var items = $('[data-mix]');

			if ( mix != "all" ) {
				items.hide();
				$('[data-mix*="' + mix + '"]').fadeIn(500);
			} else {
				items.fadeIn(500);
			}
		});
		
		// Hide/show header on scroll

		if ( $('body').hasClass('is-desktop') ) {
			header.scrollHide({
				scrollDelta: 10,
				scrollOffset: 90,
				scrollClass: 'scrollable',
				scrollClassHide: 'hide',
				onScroll: function( context, msg, currentTop ) {
					// code				
				}
			});
		}

		// Adjust padding for page without header images
		
		if ( $('body').hasClass('no-header-images') ) {
			$('#page').css( 'padding-top', headerHeight );
		}

		// Change header images for tablet devices

		if ( $('body').hasClass('is-mobile') ) {
			$('.header-images img').each(function() {
				var img = $(this);
				
				// Horizontal
				if ( $(window).width() > 991 && $(window).width() < 1200 ) {
					var dsk = img.data('img-desktop');

					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}

				// Vertical
				if ( $(window).width() > 767 && $(window).width() < 992 ) {
					var mob = img.data('img-mobile');
						
					img.attr({
						src: mob,
						"data-src": mob
					});
				}
			});

			$('.single-room-slideshow img').each(function() {
				var img = $(this);
				
				// Horizontal
				if ( $(window).width() > 991 && $(window).width() < 1200 ) {
					var dsk = img.data('img-desktop');

					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}

				// Vertical
				if ( $(window).width() > 767 && $(window).width() < 992 ) {
					var mob = img.data('img-mobile');

					img.attr({
						src: mob,
						"data-src": mob
					});
				}
			});
		}

		// Set x and y-axis of extra main title
		// Set height to extra main title container (phones)

		if ( extraTitle.length > 0 ) {
			var yAxisValue = extraTitle.outerHeight() / 2,
				xAxisValue = extraTitle.outerWidth() - yAxisValue,
				extraLeft = $('#extra .left'),
				extraRight = $('#extra .right');
			
			extraTitle.css({
				"transform-origin": xAxisValue + 'px ' + yAxisValue + 'px'
			});

			extraRight.css({
				"top": extraLeft.position().top + 'px'
			});

			if ( $(window).width() < 768 ) {
				extraRight.css({
					"height": extraTitle.outerWidth()
				});
			}
		}

	});

	// Code to run when the entire page is ready

	$( window ).on( 'load', function() {

		// Adjust margin bottom of home slideshow (desktop)

		if ( homeSlider.length > 0 ) {
			var homeCarousel = $('.home-slideshow .owl-carousel');

			if ( homeCarousel.height() > winHeight && $(window).width() > 1199 ) {
				homeSlider.css({
					"margin-bottom": homeCarousel.height() - winHeight + 'px'
				});
			}
		}

		/* Intro Animations - Begin */

		$('#page').addClass( 'init' );

		// Hamburger menu
		setTimeout(function() {
			menuButton.removeClass( 'hidden' );
		}, 1500);

		// Right header
		setTimeout(function() {
			$('#masthead .right').removeClass( 'hidden' );
		}, 1800);

		/* Intro Animations - End */

		/* GSAP Animations - Begin */

		// Menu
		var menu = gsap.timeline({
			paused: true
		});

		menu.to(
			'#masthead',
			{
				duration: 0.25,
				backgroundColor: 'transparent',
				ease: 'power2.inOut',
				stagger: 0.1,
				onReverseComplete: () => gsap.set(
					'#masthead',
					{
						clearProps: 'backgroundColor'
					}
				)
			}
		).to(
			'#menu-offcanvas',
			{
				duration: 1,
				scaleX: 1,
				ease: 'power2.inOut',
				stagger: 0.2
			}
		).to(
			'.menu-item',
			{
				duration: 0.5,
				opacity: 1,
				ease: 'power2.inOut',
				stagger: 0.1
			},
			'-=0.3'
		);

		/* GSAP Animations - End */

		// Hide/show menu off canvas

		menuButton.on('click', function(e) {
			e.preventDefault();
			
			$(this).toggleClass( 'collapsed' );

			if ( ! $(this).hasClass('collapsed') ) {
				menu.play();
			} else {
				menu.reverse();
			}

			$('body').toggleClass( 'open-menu' );
		});

		// Equal Heights
		if ( $('body').hasClass('is-desktop') || $(window).width() > 1199 ) {
			$('.equalHeight').equalHeights();
		}

	});

	// Code to run when the browser window has been resized

	$( window ).on( 'resize', function() {

		// Adjust margin bottom of home slideshow (desktop)

		if ( homeSlider.length > 0 ) {
			var homeCarousel = $('.home-slideshow .owl-carousel');

			if ( homeCarousel.height() > winHeight && $(window).width() > 1199 ) {
				homeSlider.css({
					"margin-bottom": homeCarousel.height() - winHeight + 'px'
				});
			}
		}

		// Change header images for tablet devices

		if ( $('body').hasClass('is-mobile') ) {
			var mql = window.matchMedia( "(orientation: portrait)" );
			
			$('.header-images img').each(function() {
				var img = $(this),
					org = img.data('src'),
					dsk = img.data('img-desktop');

				if ( mql.matches ) {
					// Portrait orientation
					img.attr({
						src: org,
						"data-src": org
					});
				} else {
					// Landscape orientation
					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}
			});

			$('.single-room-slideshow img').each(function() {
				var img = $(this),
					org = img.data('src'),
					dsk = img.data('img-desktop');

				if ( mql.matches ) {
					// Portrait orientation
					img.attr({
						src: org,
						"data-src": org
					});
				} else {
					// Landscape orientation
					img.attr({
						src: dsk,
						"data-src": dsk
					});
				}
			});
		}

		// Set x and y-axis of extra main title
		// Set height to extra main title container (phones)

		if ( extraTitle.length > 0 ) {
			var yAxisValue = extraTitle.outerHeight() / 2,
				xAxisValue = extraTitle.outerWidth() - yAxisValue,
				extraLeft = $('#extra .left'),
				extraRight = $('#extra .right');
			
			extraTitle.css({
				"transform-origin": xAxisValue + 'px ' + yAxisValue + 'px'
			});

			extraRight.css({
				"top": extraLeft.position().top + 'px'
			});

			if ( $(window).width() < 768 ) {
				extraRight.css({
					"height": extraTitle.outerWidth()
				});
			}
		}

	});

	// Code to run when the browser window scrolls

	var pageY = 0;
	var extra_visibility = false;
	var offers_visibility = false;
	var reviews_visibility = false;

	$( window ).on( 'scroll', function() {

		pageY = window.pageYOffset;

		if ( $(this).scrollTop() > headerHeight ) {
			header.addClass( 'bkg' );
		} else {
			header.removeClass( 'bkg' );
		}

		// Animations on scroll

		if ( $('#extra').length > 0 ) {
			var extra_offset = $('#extra').offset().top - 300;

			if ( !extra_visibility && pageY > extra_offset ) {
				extra_visibility = true;

				setTimeout(function() {
					$('#extra .covered .cover').removeClass('hidden');
				}, 250);
			}
		}

		if ( $('#offers').length > 0 ) {
			var offers_offset = $('#offers').offset().top - 300;

			if ( !offers_visibility && pageY > offers_offset ) {
				offers_visibility = true;

				$('#offers .covered').each(function(i) {
					setTimeout(function() {
						$('#offers .covered:eq(' + i + ') .cover').removeClass('hidden');
					}, 100 * i);
				});
			}
		}

		if ( $('#reviews').length > 0 ) {
			var reviews_offset = $('#reviews').offset().top - 300;

			if ( !reviews_visibility && pageY > reviews_offset ) {
				reviews_visibility = true;

				setTimeout(function() {
					$('#reviews .covered .cover').removeClass('hidden');
				}, 250);
			}
		}

	});

})(jQuery);

AOS.init({
	startEvent: "load",
	//disable: "mobile",
	easing: "ease-in-out-sine",
	once: "true"
});